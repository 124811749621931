// [NOTE] services which are set to true here, will be used with hard coded local url (port)
export const LOCAL_SERVICES = {
	accountMasterData: false,
	auth: false,
	authManagement: false,
	bpm: false,
	centrifugo: false,
	contactData: false,
	dashboardConfig: false,
	dhlAddresses: false,
	document: false,
	documentGenerator: false,
	excel: false,
	financialGuarantees: false,
	fuelCard: false,
	fuelCardComposer: false,
	fuelCardShellListPrices: false,
	journal: false,
	kmasterBoxManagement: false,
	mail: false,
	messaging: false,
	operationFigures: false,
	payment: false,
	pia: false,
	releaseNotes: false,
	svgs: false,
	toll: false,
	tollBox: false,
	tollCollect: false,
	tollEurotollDmaut: false,
	vehicle: false,
	vignette: false,
};
