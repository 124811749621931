/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcDocumentDto } from '../../models/tc-document-dto';

export interface UploadAndCreateCustomerDocument$Params {

/**
 * UUID to identify the customer
 */
  customerId: string;

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;

/**
 * the filename of the binary data associated to the document entity
 */
  name: string;

/**
 * free text that can describe the document, not searchable, not filterable
 */
  note?: string;

/**
 * if true, the document can be accessed by the customer
 */
  visibleToCustomer: boolean;

/**
 * determines the category of the document
 */
  category: 'Orders' | 'Correspondences' | 'Locks' | 'Contracts' | 'Scans' | 'Miscellaneous';
      body: Blob
}

export function uploadAndCreateCustomerDocument(http: HttpClient, rootUrl: string, params: UploadAndCreateCustomerDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentDto>> {
  const rb = new RequestBuilder(rootUrl, uploadAndCreateCustomerDocument.PATH, 'post');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('svgId', params.svgId, {});
    rb.query('name', params.name, {"explode":false});
    rb.query('note', params.note, {"explode":false});
    rb.query('visibleToCustomer', params.visibleToCustomer, {"explode":false});
    rb.query('category', params.category, {"explode":false});
    rb.body(params.body, 'application/octet-stream');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcDocumentDto>;
    })
  );
}

uploadAndCreateCustomerDocument.PATH = '/svgs/{svgId}/customers/{customerId}/documents';
