/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Result } from '../models/result';
import { updateCustomersCache } from '../fn/tc-customers-cache/update-customers-cache';
import { UpdateCustomersCache$Params } from '../fn/tc-customers-cache/update-customers-cache';

@Injectable({ providedIn: 'root' })
export class TcCustomersCacheService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCustomersCache()` */
  static readonly UpdateCustomersCachePath = '/customers-cache';

  /**
   * update tc customers cache.
   *
   * Updates tc customer cache with data from central customer api, for read models for customer independent endpoints (post auth, post filter customers and show them in result)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomersCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCustomersCache$Response(params?: UpdateCustomersCache$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return updateCustomersCache(this.http, this.rootUrl, params, context);
  }

  /**
   * update tc customers cache.
   *
   * Updates tc customer cache with data from central customer api, for read models for customer independent endpoints (post auth, post filter customers and show them in result)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomersCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCustomersCache(params?: UpdateCustomersCache$Params, context?: HttpContext): Observable<Result> {
    return this.updateCustomersCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

}
