/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcDocumentPaginatedBody } from '../../models/tc-document-paginated-body';

export interface GetCustomerDocuments$Params {

/**
 * limit for pagination
 */
  limit?: number;

/**
 * offset for pagination
 */
  offset?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortBy?: string;

/**
 * UUID to identify the customer
 */
  customerId: string;

/**
 * UUID to identify the svg (tenancy id)
 */
  svgId: string;

/**
 * show only data which was created starting with the given date (inclusive)
 */
  createdAtMin?: string;

/**
 * show only data which was created ending at the given date (inclusive)
 */
  createdAtMax?: string;

/**
 * show only data which was updated starting with the given date (inclusive)
 */
  updatedAtMin?: string;

/**
 * show only data which was updated ending at the given date (inclusive)
 */
  updatedAtMax?: string;

/**
 * determines the categories of the document
 */
  categories?: (Array<'Orders' | 'Correspondences' | 'Locks' | 'Contracts' | 'Scans' | 'Miscellaneous'>) | null;

/**
 * filter by true/false, do not filter if unspecified
 */
  visibleToCustomer?: 'unspecified' | 'false' | 'true';
}

export function getCustomerDocuments(http: HttpClient, rootUrl: string, params: GetCustomerDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentPaginatedBody>> {
  const rb = new RequestBuilder(rootUrl, getCustomerDocuments.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.path('customerId', params.customerId, {});
    rb.path('svgId', params.svgId, {});
    rb.query('createdAtMin', params.createdAtMin, {"explode":false});
    rb.query('createdAtMax', params.createdAtMax, {"explode":false});
    rb.query('updatedAtMin', params.updatedAtMin, {"explode":false});
    rb.query('updatedAtMax', params.updatedAtMax, {"explode":false});
    rb.query('categories', params.categories, {"explode":false});
    rb.query('visibleToCustomer', params.visibleToCustomer, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcDocumentPaginatedBody>;
    })
  );
}

getCustomerDocuments.PATH = '/svgs/{svgId}/customers/{customerId}/documents';
