/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { TcCustomersCacheService } from './services/tc-customers-cache.service';
import { TcInvoiceParamsService } from './services/tc-invoice-params.service';
import { HealthService } from './services/health.service';
import { TcRegistrationsService } from './services/tc-registrations.service';
import { TcDailySalesService } from './services/tc-daily-sales.service';
import { TcDocumentsService } from './services/tc-documents.service';
import { TcInvoiceSettingsService } from './services/tc-invoice-settings.service';
import { TcInvoicesService } from './services/tc-invoices.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TcCustomersCacheService,
    TcInvoiceParamsService,
    HealthService,
    TcRegistrationsService,
    TcDailySalesService,
    TcDocumentsService,
    TcInvoiceSettingsService,
    TcInvoicesService,
    ApiConfiguration
  ],
})
export class TollCollectModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<TollCollectModule> {
    return {
      ngModule: TollCollectModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TollCollectModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TollCollectModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
